import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import excelSvg from "../../assets/images/excel.svg";
import { AddCircle, DocumentDownload, Edit2, Eye, Trash } from "iconsax-react";
import ReasonModal from "../ReasonModal";
import * as XLSX from "xlsx";
import CustomPagination from "../../Utils/CustomPagination";

function List(props) {
  const [loading, setLoading] = useState(true);
  const [ClientList, setClientList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 50;
  const [filterText, setFilterText] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterName, setFilterName] = useState("");

  const [filterMobileNo, setFilterMobileNo] = useState("");
  const [filterState, setFilterState] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const [active, setActive] = useState(false);
  const csvLink = createRef();

  const showDelete = useCallback((row) => async () => {
    console.log(row.id);
    Swal.fire({
      title: "Delete Artisan",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `Client/delete?id=${row.id}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              getArtisianList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: (
        <div>
          Registered Date <br />
        </div>
      ),
      selector: (row) =>
        row.createdAt ? moment(row.createdAt).format("DD-MM-yyyy") : "",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "150px",

      // style: {
      //   color: "blue", // Set text color to blue
      // },
    },
    {
      name: "Mobile no",
      selector: (row) => row.mobileNo,
    },
    {
      name: (
        <div>
          Permanent Address <br />
        </div>
      ),
      selector: (row) => {
        const permanentAddress = [
          row?.address,
          row?.street,
          row?.city,
          row?.district,
          row?.state,
          row?.pincode,
          row?.country,
        ]
          .filter((field) => field !== null && field !== "")
          .join(", ");

        const wordCount = permanentAddress.split(" ").length;

        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Permanent Address", permanentAddress)}
          >
            <Eye />
          </RB.Button>
        ) : (
          permanentAddress || ""
        );
      },
    },
    {
      name: (
        <div>
          Current Address <br />
        </div>
      ),
      selector: (row) => {
        const currentAddress = [
          row?.current_address,
          row?.current_street,
          row?.current_city,
          row?.current_state,
          row?.current_pincode,
          row?.current_country,
        ]
          .filter((field) => field !== null && field !== "")
          .join(", ");

        const wordCount = currentAddress.split(" ").length;

        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Current Address", currentAddress)}
          >
            <Eye />
          </RB.Button>
        ) : (
          currentAddress || ""
        );
      },
    },
    {
      name: (
        <div>
          Wallet Amount <br />
        </div>
      ),
      selector: (row) => {
        const amount = parseFloat(row?.total_amount) || 0;
        return amount.toFixed(2);
      },
    },

    {
      name: "Status",
      cell: (row) => (
        <select
          //  className="form-select form-select-sm"
          value={row.status}
          onChange={(e) => handleStatusChange(row, e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Block">Block</option>
          <option value="Hold">Hold</option>
        </select>
      ),
    },

    {
      name: "View",
      cell: (row) => (
        <>
          <RB.Button className="btn-flat" onClick={handleView(row)}>
            <Eye />
          </RB.Button>
        </>
      ),
      center: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <RB.Button className="btn-flat" onClick={handelEdit(row)}>
            <Edit2 />
          </RB.Button>{" "}
          &nbsp;
          <RB.Button className="btn-flat ms-1" onClick={showDelete(row)}>
            <Trash />
          </RB.Button>
        </>
      ),
      center: true,
    },
  ];

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleSearch = () => {
    getArtisianList();
  };

  useEffect(() => {
    getArtisianList();
    // getEmployeeList();
  }, [page, filterStatus]);

  const getArtisianList = () => {
    setLoading(true);
    utils
      .callAPI(
        "get",
        `Client/list-all?date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&name=${filterName ? filterName : ""}&mobile_no=${
          filterMobileNo ? filterMobileNo : ""
        }&state=${filterState ? filterState : ""}&city=${
          filterCity ? filterCity : ""
        }&status=${
          filterStatus ? filterStatus : ""
        }&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        setLoading(false);
        if (typeof res !== "undefined") {
          setClientList(res);
          setFilterReset(false);
        } else {
          setClientList([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setClientList([]);
        setFilterReset(false);
      });
  };

  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true,
    });

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Employees");

    const csvOutput = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Client_List.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleStatusChange = (row, value) => {
    // Handle status change logic here
    var bodyFormData = new FormData();

    bodyFormData.append("client_id", row.id);
    bodyFormData.append("status", value);

    utils.callAPI("post", `Client/change-status`, bodyFormData).then((res) => {
      if (typeof res !== "undefined") {
        console.log("", res);
        const updatedClientList = [...ClientList.result];
        const updatedClientIndex = updatedClientList.findIndex(
          (item) => item.id === row.id
        );

        if (updatedClientIndex > -1) {
          updatedClientList[updatedClientIndex] = { ...row, status: value };

          setClientList({ ...ClientList, result: updatedClientList });
        }
      } else {
      }
    });

    console.log("Status changed to:", value, row.id);
  };

  const handleDate = (date) => {
    setFilterDate(date);
  };

  function handleDownload() {
    console.log("Handle Download Clicked");
    setCsvData([]);

    utils
      .callAPI(
        "get",
        `Client/export?date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&name=${filterName}&mobile_no=${filterMobileNo}&state=${filterState}&city=${filterCity}&status=${filterStatus}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          // setCsvData(res);
          // setActive(true);
          generateCSV(res);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true,
    });

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Employees");

    const csvOutput = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Client_List.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleReset = () => {
    setFilterDate("");
    setFilterStatus("");
    setFilterName("");
    setFilterMobileNo("");
    setFilterState("");
    setFilterCity("");
    setFilterReset(true);
    // setTimeout(() => {
    // getArtisianList("");
    // }, 0);
  };

  useEffect(() => {
    if (filterReset) {
      getArtisianList("");
    }
  }, [filterReset]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="section-body">
      <RB.Card className="card">
        <div className="floating-btns-holder-artisan">
          <a
            href
            className="btn btn-primary btn-circle me-2"
            variant="primary"
            onClick={() => handleDownload()}
          >
            <DocumentDownload />
          </a>
          <a
            href
            className="btn btn-dark btn-circle"
            variant="dark"
            onClick={handleAddClick}
          >
            <AddCircle />
          </a>
        </div>

        <RB.Card.Body>
          <div className="row g-2 mb-3">
            <div className="col-md-2">
              {/* <input
                type="text"
                className="form-control"
                placeholder="Date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              /> */}

              <DatePicker
                onChange={handleDate}
                value={filterDate}
                selected={filterDate ? new Date(filterDate) : null}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                className="form-control  range-datepicker-input-artisan"
                placeholderText="Date"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="Name"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                maxLength={32}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="Mobile No"
                value={filterMobileNo}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\D/g, "");
                  setFilterMobileNo(cleanedValue);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={10}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control"
                placeholder="State"
                value={filterState}
                onChange={(e) => setFilterState(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={32}
              />
            </div>
            <div className="col-md">
              <input
                type="text"
                className="form-control "
                placeholder="City"
                value={filterCity}
                onChange={(e) => setFilterCity(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                maxLength={32}
              />
            </div>
            <div className="col-md">
              <select
                //  className="form-select form-select-sm"
                className="form-control "
                placeholder="Status"
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Block">Block</option>
                <option value="Hold">Hold</option>
              </select>
            </div>
            <div className="col-md">
              <RB.Button
                className=" btn-md"
                variant="primary"
                onClick={handleSearch}
                disabled={
                  !filterCity &&
                  !filterDate &&
                  !filterMobileNo &&
                  !filterName &&
                  !filterState &&
                  !filterStatus
                }
              >
                Find
              </RB.Button>
              <Link
                className="ms-2 btn-underline"
                // variant="secondary"
                onClick={handleReset}
              >
                Reset
              </Link>
            </div>
          </div>
          {/* <RB.Row className="justify-content-between">
            <RB.Col md="auto">
              <div className="d-flex justify-content-start" text-center>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option>Select an employee...</option>
                  {empList?.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  ))}
                </RB.Form.Select>
                <RB.Form.Select
                  aria-label="Default select example"
                  className="me-2"
                  value={selectedMonthYear}
                  onChange={(e) => setSelectedMonthYear(e.target.value)}
                >
                  <option value="">Select a month-year...</option>
                  {months}
                </RB.Form.Select>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={handleFilter}
                >
                  Find
                </RB.Button>
                <RB.Button
                  className="ms-2"
                  variant="secondary"
                  onClick={handleReset}
                >
                  Reset
                </RB.Button>
              </div>
            </RB.Col>
            <RB.Col md="auto">
              {" "}
              <div className=" justify-content-end" text-center>
                <RB.Button
                  className="ms-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  Download
                </RB.Button>

                <CSVLink data={csvData} ref={csvLink} target="_blank" />
              </div>
            </RB.Col>
          </RB.Row> */}
          <DataTable
            columns={columns}
            data={ClientList.result}
            highlightOnHover
            responsive
            striped
            pagination
            paginationServer
            paginationTotalRows={ClientList.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            paginationComponent={() => (
              <CustomPagination
                currentPage={page}
                totalRows={ClientList.total}
                rowsPerPage={countPerPage}
                onPageChange={handlePageChange}
              />
            )}
            progressPending={loading}
            onChangePage={(page) => setPage(page)}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },
            }}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </div>
  );
}

export default List;
