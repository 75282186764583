import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import CustomPagination from '../../Utils/CustomPagination'
import { useSelector } from "react-redux";
import { Edit, Edit2, ProfileAdd, Trash } from "iconsax-react";

function List(props) {
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => (
        <RB.Form.Check
          type="switch"
          id={`status-switch-${row.id}`}
          checked={row.isActive === "1"}
          onChange={handleStatusChange(row)}
          label={`${row.isActive == "1" ? "Active" : "inActive"}`}
        />
      ),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {/* {utils.hasPermission(primaryMenuRes, "User", "user.edit") && (
            <RB.Button
              className="btn-flat"
              title="Edit"
              onClick={handelEdit(row)}
            >
              <Edit2 />
            </RB.Button>
          )} */}
          {row?.user_role[0]?.roleName !== "Super Admin"
            ? utils.hasPermission(primaryMenuRes, "User", "user.delete") && (
                <RB.Button
                  className="btn-flat ms-1"
                  title="Delete"
                  onClick={showDelete(row)}
                >
                  <Trash />
                </RB.Button>
              )
            : ""}
          {utils.hasPermission(primaryMenuRes, "User", "user.permission") && (
            <RB.Button
              className="btn btn-flat"
              title="Assign Permission"
              onClick={handleAssignPermission(row)}
            >
              <ProfileAdd />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  const handleAssignPermission = useCallback((row) => async () => {
    const propdata = {
      mode: "permission",
      rowData: row,
    };
    props.onChange(propdata);
  });

  useEffect(() => {
    getUserList();
  }, [page, filterText]);

  const getUserList = () => {
    utils
      .callAPI(
        "get",
        `/users/users-list?search=${filterText}&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setUserList(res);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        setUserList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleStatusChange = useCallback((row) => async () => {
    console.log(row);
    const newStatus = row.isActive === "1" ? "0" : "1";

    var bodyFormData = new FormData();
    bodyFormData.append("id", row.id);
    bodyFormData.append("firstName", row.firstName);
    bodyFormData.append("middleName", row.middleName);
    bodyFormData.append("lastName", row.lastName);
    bodyFormData.append("mobileNo", row.mobileNo);
    bodyFormData.append("email", row.email);
    bodyFormData.append("isActive", newStatus);
    bodyFormData.append("roleId", row.roleId);
    utils
      .callAPI("post", `/users/users-edit`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          getUserList();
        }
      })
      .catch((err) => {});
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/users/users-delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getUserList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        {/* <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            {utils.hasPermission(primaryMenuRes, "User", "user.create") && (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add User
              </RB.Button>
            )}
          </div>
        </RB.Card.Header> */}
        <RB.Card.Body>
          {utils.hasPermission(primaryMenuRes, "User", "user.view") && (
            <DataTable
              columns={columns}
              data={userList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={userList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={userList.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
