import { useEffect } from "react";

const NoPermission = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      localStorage.removeItem("access_token");
      window.location.replace("/");
    }, 3000);

    return () => clearTimeout(timeout); 
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 text-center flex-column">
      <h1>You don’t have permission, please contact admin.</h1>
      <div className="d-flex align-items-center mt-3">
        <div className="spinner-border text-primary me-2" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p className="mb-0">Redirecting to login...</p>
      </div>
    </div>
  );
};

export default NoPermission;
